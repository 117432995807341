import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src='/freeborn.png' className="App-logo" alt="logo" />
        <a
          className="App-link"
          href="mailto:hello@freebornstudios.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          CONTACT US
        </a>
      </header>
    </div>
  );
}

export default App;
